<template>
  <div class="product">
    <div class="zhft">
      <div class="zhft_box">
        <p>电子会务</p>
        <p>构建无纸化会务新模式</p>
        <p>帮助党政机关单位构建高效率、绿色低能耗的电子会务系统</p>
        <el-button>在线咨询</el-button>
      </div>
    </div>
    <div class="hxld">
      <p>核心亮点</p>
      <div class="hxld_box">
        <img class="img" id="img" src="@/assets/images/product/dzhw/hxld.png" />
        <div class="hxld_box_content" id="title">
          <div class="hxld_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/hxld_icon1.png"
              />
              <p class="first_line">签批联动</p>
              <p class="second_line">
                领导签批与议题生成联动，<br />议题呈批通过后，可一键同步到会议系统
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/hxld_icon2.png"
              />
              <p class="first_line">资料安全</p>
              <p class="second_line">多重手段护航，<br />安全查阅议题与材料</p>
            </div>
          </div>
          <div class="hxld_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/hxld_icon3.png"
              />
              <p class="first_line">议题管理</p>
              <p class="second_line">
                议题资料电子化管理，<br />议题的跟进与审核
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/hxld_icon4.png"
              />
              <p class="first_line">查看议题</p>
              <p class="second_line">
                领导会前在OA上审批议题，<br />提前掌握议题内容
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qxkz">
      <div class="qxkz_box">
        <div class="qxkz_box_content" id="title2">
          <div class="qxkz_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/qxkz_icon1.png"
              />
              <p class="first_line">权限控制</p>
              <p class="second_line">
                同一会场可灵活控制参会人员<br />查看议题资料的权限
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/qxkz_icon2.png"
              />
              <p class="first_line">灵活修改</p>
              <p class="second_line">
                议题资料会前、会中可随时修改，<br />并实时同步到参会阅读设备上
              </p>
            </div>
          </div>
          <div class="qxkz_box_item">
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/qxkz_icon3.png"
              />
              <p class="first_line">自动同步</p>
              <p class="second_line">
                自动同步最新的会议资料<br />到参会阅读设备
              </p>
            </div>
            <div class="title">
              <img
                class="icon"
                src="@/assets/images/product/dzhw/qxkz_icon4.png"
              />
              <p class="first_line">无纸会议</p>
              <p class="second_line">
                使用电子设备阅读会议资料，<br />支持苹果（ios)、安卓(Android）系统
              </p>
            </div>
          </div>
        </div>
        <img
          class="img"
          id="img2"
          src="@/assets/images/product/dzhw/qxkz.png"
        />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  data () {
    return {

    }
  },
  mounted () {
    $(window).scroll(this.scrollBar)
  },
  methods: {
    scrollBar () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      var hxldH = $('.hxld').offset().top
      var qxkzH = $('.qxkz').offset().top
      if (
        h > hxldH - 500
      ) {
        $('#img').addClass('animation')
      }
      if (h > qxkzH - 500) {
        $('#img2').addClass('animation')
      }
    }
  },
  destroyed () {
    $(window).unbind('scroll', this.scrollBar)
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
